export const tableMixins = (extraHeight, className = 'table--layout') => {
    return {
        data () {
            return {
                scroll: { y: 0 }
            }
        },
        mounted () {
            this.handleSetTableStyle(window.innerHeight)
            window.addEventListener('resize', this.handleResize)
            this.$once('beforeDestroy:hook', () => {
                window.removeEventListener('resize', this.handleResize)
            })
        },
        methods: {
            handleResize (e) {
                const height = e.target.innerHeight
                this.handleSetTableStyle(height)
            },
            handleSetTableStyle (height) {
                const tableHeight = height - extraHeight
                this.$set(this.scroll, 'y', (tableHeight > 0 ? tableHeight : 1))
                this.$nextTick(() => {
                    const tableEle = this.$el.querySelector(`.${className}`).querySelector('.ant-table-body')
                    const realHeight = tableHeight > 0 ? tableHeight : 1
                    tableEle.style.height = `${realHeight}px`
                })
            }
        }
    }
}
