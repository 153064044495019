<template>
  <div class="evaluate__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      用工评价管理
    </div>
    <div class="content">
      <a-row>
        <a-col :span="24">
          <div
            class="recruit_tab"
            style="margin-bottom: 24px"
          >
            <a-row>
              <a-col :span="5">
                <div
                  class="recruit_tabItem"
                  :class="[val == 1?'active_recruit_tabItem':'']"
                  style="border-right: 0;"
                  @click="getTab(1)"
                >已评价</div>
              </a-col>
              <a-col :span="5">
                <div
                  class="recruit_tabItem"
                  :class="[val == 0?'active_recruit_tabItem':'']"
                  @click="getTab(0)"
                >待评价</div>
              </a-col>
            </a-row>
          </div>
          <a-row type="flex">
            <a-col :span="24">
              <a-row>
                <a-col :span="6">
                  <div class="recruitUse_text">
                    <a-row>
                      <a-col :span="6">项目部:</a-col>
                      <a-col :span="12">
                        <a-select
                          ref="select"
                          v-model="projectDepartmentId"
                          :options="Departmentlist"
                          placeholder="请选择项目部"
                          @change="changeDepartment"
                          style="width: 200px"
                          allowClear
                        ></a-select>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="recruitUse_text">
                    <a-row>
                      <a-col :span="6">项目名称:</a-col>
                      <a-col :span="12">
                        <a-select
                          ref="select"
                          v-model="projectId"
                          :options="Projectlist"
                          placeholder="请选择项目名称"
                          @change="changeProjectlist"
                          style="width: 200px"
                          allowClear
                        ></a-select>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="recruitUse_text">
                    <a-row>
                      <a-col :span="6">子项目名称:</a-col>
                      <a-col :span="12">
                        <a-select
                          ref="select"
                          v-model="demandId"
                          :options="Positionlist"
                          placeholder="请选择子项目名称"
                          @change="changePosition"
                          style="width: 200px"
                          allowClear
                        ></a-select>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
              </a-row>
              <a-spin :spinning="spinning">
                <div
                  class
                  style="margin-top: 24px"
                  v-if="val ==1"
                >
                  <a-table
                    class="table--layout"
                    :scroll="{y: 480}"
                    @change="handleTableChange"
                    :columns="columns"
                    :data-source="tableData"
                    :pagination="pagination"
                  >
                    <template
                      slot="score"
                      slot-scope="record"
                    >
                      <a-rate
                        :default-value="Number(record)"
                        slot="score"
                        disabled
                      />
                    </template>
                    <template
                      slot="operation"
                      slot-scope="text,record"
                    >
                      <div
                        class="operation_btn"
                        @click="detail(record)"
                      >详情</div>
                    </template>
                  </a-table>
                </div>
                <div
                  class
                  style="margin-top: 24px"
                  v-if="val ==0"
                >
                  <a-table
                    class="table--layout"
                    :scroll="{y: 480}"
                    @change="handleTableChange"
                    :columns="columns"
                    :data-source="tableData"
                    :pagination="pagination"
                  >
                    <template
                      slot="operation"
                      slot-scope="text,record"
                    >
                      <a-button
                        type="link"
                        @click="Evaluate(record)"
                      >评价</a-button>
                    </template>
                  </a-table>
                </div>
              </a-spin>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <evaluate ref="evaluate" />
  </div>
</template>

<script>
import {
  employmentEmployDepartment,
  enterpriseEmploymentManagement,
  queryAllWbProject,
  queryByMy
} from '@/api/recruitUse'
import { tableMixins } from '@/mixins/table-height'
import moment from 'moment'
import evaluate from './evaluate'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    customRender: (text, record, index) => index + 1
  },
  {
    title: '姓名',
    dataIndex: 'realName'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    customRender: (text) => (text === 0 ? '男' : (text === 1 ? '女' : '其他'))
  },
  {
    title: '年龄',
    dataIndex: 'age'
  },
  {
    title: '联系电话',
    dataIndex: 'phone'
  },
  {
    title: '评分结果',
    dataIndex: 'score',
    scopedSlots: { customRender: 'score' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'Index',
  mixins: [tableMixins(425, 'table--layout')],
  components: {
    evaluate
  },
  data() {
    return {
      autoExpandParent: true,
      projectDepartmentId: undefined,
      demandId: undefined,
      projectId: undefined,
      visible: false,
      val: 1,
      isEvaluate: true,
      Departmentlist: [],
      Projectlist: [],
      Positionlist: [],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      content: '',
      evaluationDetail: {},
      typeList: [],
      typeSelect: [],
      rate: 0,
      evaluationRow: '',
      spinning: false,
      confirmLoading: false,
      desc: ['非常不满意', '不满意', '一般', '满意', '非常满意'],
      tagOptions: []
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    columns() {
      return columns.filter(item => this.val === 1 || item.dataIndex !== 'score')
    }
  },
  methods: {
    init() {
      // this.handleGetTypeList()
      employmentEmployDepartment()
        .then(res => {
          res.data.forEach(v => {
            v.label = v.projectDepartment
            v.value = v.projectDepartmentId
          })
          this.Departmentlist = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.handleGetData()
    },
    // handleGetTypeList () {
    //   getTypeDetail().then(res => {
    //     this.typeList = res.data
    //   })
    // },
    changePosition(val) {
      this.handleGetData()
    },
    handleGetData() {
      this.spinning = true
      const params = {
        pageIndex: this.pagination.current,
        pageSize: this.pagination.pageSize,
        demandId: this.demandId,
        projectDepartmentId: this.projectDepartmentId,
        projectId: this.projectId,
        status: this.val
      }
      if (params) {
        queryByMy(params).then(res => {
          this.tableData = res.data
          this.spinning = false
          this.pagination.total = res.totalCount
        })
      } else {
        this.tableData = []
        this.spinning = false
        this.pagination.current = 1
        this.pagination.pageSize = 10
        this.pagination.total = 0
      }
    },
    changeProjectlist(val) {
      this.demandId = undefined
      this.Positionlist = []
      if (val) {
        const params = {
          projectId: val
        }
        queryAllWbProject(params)
          .then(res => {
            console.log(res)
            res.data.forEach(v => {
              v.label = v.wbProject
              v.value = v.demandId
            })
            this.Positionlist = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }
      this.handleGetData()
    },
    // 获取项目名称
    changeDepartment(val) {
      this.projectId = undefined
      this.demandId = undefined
      this.Projectlist = []
      this.Positionlist = []
      if (val) {
        const params = {
          projectDepartmentId: val
        }
        enterpriseEmploymentManagement(params)
          .then(res => {
            res.data.forEach(v => {
              v.label = v.projectName
              v.value = v.projectId
            })
            this.Projectlist = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }
      this.handleGetData()
    },
    Evaluate(row) {
      this.$refs.evaluate.init({
        signId: row.id,
        author: row.enterpriseId,
        authorName: row.enterpriseName,
        clientId: 'enterprise',
        userId: row.userId,
        disabled: true
      })
    },
    getTab(val) {
      this.val = val
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.tableData = []
      this.handleGetData()
    },
    detail(row) {
      this.$refs.evaluate.lookInit({
        row: row,
        disabled: false
      })
      // this.evaluationDetail.typeList = row.tab ? row.tab.split(',') : []
    },
    handleTableChange(pagination) {
      this.pagination = pagination
      this.handleGetData()
    },
    handleTypeClick(key) {
      if (this.typeSelect.includes(key)) {
        this.typeSelect = this.typeSelect.filter(item => item !== key)
      } else {
        this.typeSelect.push(key)
      }
    },
    formatTime(time) {
      return time ? moment(time).format('YYYY-MM-DD') : null
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('../Frecruit/index');
@import url('./index');
.evaluate__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }
}
</style>
